<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="main">
              <div class="notice_main">
                <div class="main_title card-title">工单详情</div>
                <div class="main_table">
                  <div class="main-left">
                    <div id="a-chat" ref="aChat" class="chat-box">
                      <div v-for="(v, index) in replayList" :key="index">
                        <div v-if="v.replyType == leftViewId" style="" class="chat-line">
                          <div style="display: flex;text-align: right;float: right ">
                            <div style="margin-right: 10px">
                              <div class="grey-time">{{ formatDateDetails(v.createTime) }}</div>
                              <div
                                style="background: rgb(236,244,251); padding: 5px;white-space: pre-wrap; word-break: break-all;border-radius: 5px 0 5px 5px">
                                <div>
                                  <div style="line-height: 20px">{{ v.replyInfo }}</div>
                                  <div style="border-top: 1px solid  #EBEEF5;">
                                    <div v-for="(v1, index) in v.files" :key="index">
                                      <a @click="$yzImgDialog().show(getFileUrl(v1.fileUrl))" href="javascript:;"><i
                                          class="fa fa-link" aria-hidden="true"></i> {{ v1.fileName }}</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <img src="@/assets/images/users/avatar-1.jpg" alt="" class="avatar">
                          </div>
                        </div>
                        <div v-if="v.replyType == rightViewId" class="chat-line">
                          <div style="display: flex;text-align: left">
                            <img src="@/assets/images/users/avatar-2.jpg" alt="" class="avatar">
                            <div style="margin-left: 10px">
                              <div class="grey-time"><span style="margin-right: 10px">{{ v.xm }}</span>
                                {{ formatDateDetails(v.createTime) }}
                              </div>
                              <div
                                style="background: rgb(236,244,251); padding: 5px;white-space: pre-wrap; word-break: break-all;border-radius: 0 5px 5px 5px">
                                <div>
                                  <div style="line-height: 20px;">{{ v.replyInfo }}</div>
                                  <div style="border-top: 1px solid  #EBEEF5;">
                                    <div v-for="(v1, index) in v.files" :key="index">
                                      <a @click="$yzImgDialog().show(getFileUrl(v1.fileUrl))" href="javascript:;"><i
                                          class="fa fa-link" aria-hidden="true"></i> {{ v1.fileName }}</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="text-align: center;padding: 50px" v-if="workOrderApply.applyStatus == 1">
                        <!--                超时后台关闭工单!-->
                        <div style="margin: 0 auto; position: relative;">
                          <div
                            style="width: 300px;border-top: 1px solid #C0C4CC;margin: 0 auto; position: absolute;left: 0;right: 0;">
                          </div>
                          <div
                            style="width:200px; color: #C0C4CC; position: absolute; top: -17px;margin: 0 auto; background: rgb(247,247,247);padding: 0 10px;left: 0;right: 0;">
                            {{ workOrderApply.statusTitle }}工单!
                          </div>
                        </div>
                        <div style="width: 300px; background: white; margin: 20px auto; padding: 20px">
                          <div><span
                              style="color: red">*</span>&nbsp;<strong>本次服务的评价</strong>
                          </div>
                          <div>
                            <el-rate v-model="workOrderApplyForm.applyEvaluate" show-text
                              :disabled="!isEvaluate || workOrderReplyForm.replyType==2"></el-rate>
                          </div>
                          <div v-if="workOrderApplyForm.applyEvaluate > 0 && isEvaluate"
                            style="margin-top: 10px; text-align: center">
                            <el-button size="mini" style="background: rgb(0,100,200); color: white"
                              @click="evaluateWorkOrder">
                              提交</el-button>
                            <el-button size="mini" @click="workOrderApplyForm.applyEvaluate = 0">重置</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 20px 10px" v-if="workOrderReplyForm.replyType != 3">
                      <div style="text-align: right; color: #409EFF"
                        v-if="workOrderReplyForm.replyType == 1 && workOrderApply.applyStatus != 1">
                        <el-popover placement="top" width="200" trigger="click" v-model="workOrderVisible">
                          <div style=""><i class="el-icon-warning" style="color: #E6A23C"></i> 关闭工单</div>
                          <div style="font-size: 10px; margin: 0 5px">当您确认工单问题已经得到解决，您可关闭工单，关闭工单后如果有新的问题，您可以提交新工单咨询。
                          </div>
                          <div style="text-align: right">
                            <el-button size="mini" style="background: rgb(0,100,200); color: white"
                              @click="closeWorkOrder">确认
                            </el-button>
                            <el-button size="mini" @click="workOrderVisible = false">取消</el-button>
                          </div>
                          <span style="cursor: pointer" slot="reference"><strong><i class="el-icon-switch-button"></i>
                              关闭工单</strong></span>
                        </el-popover>
                      </div>
                      <div style="text-align: right; color: #409EFF" class="mb-2"
                        v-if="workOrderReplyForm.replyType == 2 && workOrderApply.applyStatus != 1">
                        <el-popover placement="top" width="200" trigger="manual" v-model="workOrderShareVisible">
                          <div style="">
                            <i class="el-icon-warning" style="color: #E6A23C"></i> 转发
                          </div>
                          <div style="font-size: 10px; margin: 0 5px">转发后该工单将不由你管理，即将转送到 {{workOrderApplyForm.dealXm}}
                            处理，确认转发
                          </div>
                          <div style="text-align: right">
                            <el-button size="mini" style="background: rgb(0,100,200); color: white"
                              @click="shareWorkOrder">确认
                            </el-button>
                            <el-button size="mini" @click="workOrderShareVisible = false">取消</el-button>
                          </div>
                          <span style="cursor: pointer" slot="reference" @click="clickWorkOrderShare">
                            <strong><i class="fa fa-share" aria-hidden="true"></i> 转发</strong>
                            <el-select v-model="workOrderApplyForm.dealDay" size="mini"
                              style="width: 80px; margin-left: 10px">
                              <el-option :label="v+'天'" :value="v" v-for="(v, index) in 7" :key="index">{{v+'天'}}
                              </el-option>
                            </el-select>
                          </span>
                        </el-popover>
                      </div>
                      <el-input :disabled="workOrderApply.applyStatus == 1" v-model="workOrderReplyForm.replyInfo"
                        type="textarea" placeholder="请输入信息" class="mb-2"></el-input>
                      <div style="display: flex; justify-content: space-between">
                        <span>
                          <input type="file" multiple="multiple" @change="uploadFile" style="display: none"
                            id="upload_file">
                          <span @click="startUploadFile" style="cursor: pointer"><i class="fa fa-link"
                              aria-hidden="true"></i>上传附件</span>
                          <a :title="v.fileName" @click="$yzImgDialog().show(getFileUrl(v1.fileUrl))"
                            href="javascript:;" v-for="(v, index) in uploadFiles" :key="index"
                            class="file_item">{{v.fileName}}</a>
                        </span>
                        <span>
                          <el-button size="mini" @click="replyInfo" :disabled="workOrderApply.applyStatus == 1">发送
                          </el-button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="work-right">
                    <div class="mb-2 text-dark">工单信息</div>
                    <div>
                      <table class="order-info-table">
                        <tr>
                          <th style="width: 65px">工单编号</th>

                          <td>
                            <el-input v-model="workOrderApply.wid" disabled size="mini"></el-input>
                          </td>
                        </tr>
                        <tr>
                          <th>问题描述</th>
                          <td>{{ workOrderApply.applyTitle }}</td>
                        </tr>
                        <tr>
                          <th>优先级</th>
                          <td>{{ workOrderApply.applyLevel == 'normal' ? '普通' : '重要' }}</td>
                        </tr>
                        <tr>
                          <th>工单状态</th>
                          <td><i class="fa fa-circle" aria-hidden="true"
                              :style="{color: applyStatus[workOrderApply.applyStatus]!=undefined?applyStatus[workOrderApply.applyStatus].color:'#000'}"></i>
                            {{ applyStatus[workOrderApply.applyStatus]!=undefined?applyStatus[workOrderApply.applyStatus].title:'' }}
                          </td>
                        </tr>
                        <tr>
                          <th>提交账号</th>
                          <td>{{ workOrderApply.applyXm }}</td>
                        </tr>
                        <tr>
                          <th>提交时间</th>
                          <td>{{ formatDate(workOrderApply.dealStartTime) }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div style="padding: 10px 0; text-align: left;">
                  <div style="width: 1100px; margin: 0 auto">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <chooseAdmin ref="chooseAdmin" @callback="choosePerson"></chooseAdmin>
  </Layout>
</template>

<script>
  import $ from 'jquery'
  import {
    workOrderApply
  } from "@/api/admin/workOrder/apply";
  import {
    workOrderReply,
    workOrderReplyAdd
  } from "@/api/admin/workOrder/reply";
  import {
    uploadFiles
  } from "@/api/common/public";
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    workOrderApplyClose,
    workOrderApplyEvaluate,
    workOrderApplyShare
  } from "@/api/admin/workOrder/apply";
  import chooseAdmin from "@/components/user/chooseAdmin";

  export default {
    name: "workOrderHandle",
    data() {
      return {
        title: "工单受理 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "工单受理",
            active: true
          }
        ],
        workOrderVisible: false,
        replayList: [],
        imgShow: false,
        workOrderApply: {
          id: '',
          wid: '', // 需要
          sid: '',
          sortName: '',
          tid: '',
          typeName: '',
          dwh: '',
          dwmc: '',
          dwjc: '',
          gh: '',
          xm: '',
          dealGh: '',
          dealXm: '',
          replyStatus: '',
          applyLevel: '',
          applyTitle: '',
          applyContent: '',
          isPublic: '',
          applyStatus: '',
          statusTitle: '',
          applyEvaluate: 0,
          applyGh: '',
          applyXm: '',
          dealDay: '',
          dealStartTime: '',
          dealEndTime: '',
          createTime: '',
          modifyUniversityId: '',
          modifyUser: '',
          modifyTime: '',
          modifyIp: '',
          isChoose: false,
        },
        applyStatus: [{
          title: '已受理',
          color: "#67C23A"
        }, {
          title: '已完结',
          color: "#000000"
        }, {
          title: '正在处理',
          color: "#409EFF"
        }, {
          title: '已回复',
          color: "#E6A23C"
        }, {
          title: '待用户反馈',
          color: "#F56C6C"
        }],
        workOrderReplyForm: {
          sid: '',
          wid: '',
          gh: '',
          xm: '',
          replyType: '',
          replyInfo: '',
          files: []
        },
        workOrderApplyForm: {
          id: '',
          wid: '', // 需要
          sid: '',
          sortName: '',
          tid: '',
          typeName: '',
          dwh: '',
          dwmc: '',
          dwjc: '',
          gh: '',
          xm: '',
          dealGh: '',
          dealXm: '',
          replyStatus: '',
          applyLevel: '',
          applyTitle: '',
          applyContent: '',
          isPublic: '',
          applyStatus: '',
          statusTitle: '',
          applyEvaluate: 0,
          applyGh: '',
          applyXm: '',
          dealDay: 1,
          dealStartTime: '',
          dealEndTime: '',
          createTime: '',
          modifyUniversityId: '',
          modifyUser: '',
          modifyTime: '',
          modifyIp: '',
          isChoose: false,
        },
        isEvaluate: true,
        workOrderShareVisible: false,
        uploadFiles: [],
        websocket: null,
        leftViewId: 1,
        rightViewId: 2,
      }
    },
    components: {
      chooseAdmin,
      PageHeader,
      Layout
    },
    methods: {
      init() {
        this.uploadFiles = []
        this.workOrderReplyForm = {}
        this.workOrderReplyForm.wid = this.$route.query.wid
        this.workOrderApplyForm.wid = this.$route.query.wid
        this.workOrderReplyForm.gh = this.userInfo.yhzh
        this.workOrderReplyForm.xm = this.userInfo.xm
        this.getWorkOrderApply()
      },
      initWebSocket() {
        this.websocket = null;
        //判断当前浏览器是否支持WebSocket, 主要此处要更换为自己的地址
        if ('WebSocket' in window) {
          this.websocket = new WebSocket(`${this.WEB_SOCKET_URL}/yethan/WorkOrder`);
        } else {
          alert('Not support websocket')
        }
        //连接发生错误的回调方法
        this.websocket.onerror = function() {};

        //连接成功建立的回调方法
        let that = this
        this.websocket.onopen = function(event) {
          if (that.websocket.readyState == 1)
            that.websocket.send(JSON.stringify({
              url: that.$route.query.wid,
              status: 1
            }));
        }

        //接收到消息的回调方法
        this.websocket.onmessage = function(event) {
          that.init()
        }

        //连接关闭的回调方法
        this.websocket.onclose = function() {}

        //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = function() {
          this.websocket.close();
        }


      },
      uploadFile(ev) {
        var files = ev.target.files;
        for (let i = 0; i < files.length; i++) {
          uploadFiles('WorkOrder', files[i]).then(res => {
            if (res.status) {
              const fileInfo = res.data[0]
              this.uploadFiles.push({
                fileSort: new Date().getTime().toString().slice(0, 8),
                fileName: fileInfo.name,
                fileUrl: fileInfo.fileId,
                fileType: fileInfo.ext,
                fileSize: fileInfo.size,
              })
            }
          })
        }
      },
      startUploadFile() {
        if (this.workOrderApply.applyStatus == 1) {
          this.$notify.warning("工单已经关闭，如需帮助请申请新的工单")
          return
        }
        $('#upload_file').click()
      },
      getWorkOrderApply() {
        workOrderApply(this.$route.query.wid).then(res => {
          if (res.status) {
            this.workOrderApply = res.data
            this.workOrderApply.files = this.workOrderApply.fileList
            this.isEvaluate = this.workOrderApply.applyEvaluate == 0
            this.workOrderApplyForm.applyEvaluate = this.workOrderApply.applyEvaluate
            if (this.workOrderApply.applyGh == this.userInfo.yhzh) {
              this.workOrderReplyForm.replyType = '1'
              this.rightViewId = 2
              this.leftViewId = 1

            } else if (this.workOrderApply.dealGh == this.userInfo.yhzh || this.workOrderApply.gh == this.userInfo
              .yhzh) {
              this.workOrderReplyForm.replyType = '2'
              this.rightViewId = 1
              this.leftViewId = 2
            } else {
              window.open("/commonhome/error?error=noPower", "_self")
              return
            }
            this.replayList = []
            this.replayList.push({
              createTime: res.data.dealStartTime,
              replyInfo: res.data.applyContent,
              replyType: 1,
              files: this.workOrderApply.fileList
            })
            workOrderReply(this.$route.query.wid).then(res => {
              if (res.status) {
                res.data.forEach(item => {
                  item.files = item.fileList
                })
                this.replayList = this.replayList.concat(res.data)
                let that = this
                this.$nextTick(function() {
                  that.$refs.aChat.scrollTop = 99999999999
                })

              }
            })
          }
        })
      },
      sendMessage() {
        if (this.websocket.readyState == 1) {
          this.websocket.send(JSON.stringify({
            url: this.$route.query.wid,
            status: 2
          }));
        }
      },
      replyInfo() {
        if (!this.workOrderReplyForm.replyInfo) {
          this.$notify.warning("请输入信息")
          return
        }
        this.workOrderReplyForm.files = JSON.stringify(this.uploadFiles)
        workOrderReplyAdd(this.workOrderReplyForm.wid, this.workOrderReplyForm).then(res => {
          if (res.status) {
            this.$notify.success("成功");
            // this.init()
            this.sendMessage()
          }
        })
      },
      closeWorkOrder() {
        workOrderApplyClose(this.workOrderApplyForm.wid).then(res => {
          if (res.status) {
            this.sendMessage()
          }
        })
      },
      evaluateWorkOrder() {
        workOrderApplyEvaluate(this.workOrderApplyForm.wid, this.workOrderApplyForm).then(res => {
          if (res.status) {
            this.sendMessage()
          }
        })
      },
      choosePerson(e) {
        this.workOrderApplyForm.dealXm = e.xm
        this.workOrderApplyForm.dealGh = e.yhzh
        this.workOrderShareVisible = true
      },
      clickWorkOrderShare() {
        if (this.workOrderApplyForm.isChoose) {
          this.workOrderShareVisible = true
        } else {
          this.$refs.chooseAdmin.show()
        }
      },
      shareWorkOrder() {
        workOrderApplyShare(this.workOrderReplyForm.wid, this.workOrderApplyForm).then(res => {
          if (res.status) {
            window.location.reload()
          }
        })
      }
    },
    mounted() {
      this.init()
      this.initWebSocket()
    }
  }
</script>
<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style scoped>
  .show-font {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 140px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .show-font:hover {
    height: auto;
    white-space: normal;
  }

  .order-info-table th {
    color: #767a82;
    
  }

  .file_item {
    overflow: hidden;
    margin-left: 10px;
    display: inline-flex;
    max-width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: left;
  }

  .main_table {
    display: flex;
    width: 100%;
    min-width: 800px
  }

  .main-left {
    width: 80%;
    border: 1px solid #EBEEF5
  }

  .chat-box {
    height: 500px;
    overflow: auto;
    background: rgb(247, 247, 247);
    padding: 20px
  }

  .chat-line {
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden
  }

  .avatar {
    height: 50px;
    width: 50px;
    border-radius: 25px
  }

  .work-right {
    width: 20%;
    text-align: left;
    padding: 10px;
    border: 1px solid #EBEEF5
  }
</style>
